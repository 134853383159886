import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../layout";
import { BlogEntry, DesktopRightImage, Image } from "../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">Bjørn Jarle Kvande</h1>
    <h3 className="text-center text-lg sm:text-2xl">
  Koderelatert arbeid, tanker, idéer, og frustrasjoner
    </h3>
    <br />
    <Image src="/coding/programmerspet.jpg" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9">
  Hei, Bjørn Jarle heter jeg. Jeg bygger ting med kode. Her finner du en samling av 
  innlegg om koding, tanker, idéer, og frustrasjoner som farer gjennom hodet på en 
  utvikler som har vært med siden Commodore 64 sin storhetstid. Jeg elsker 
  faget mitt, jobber ustanselig for å bli bedre, er godt ute på spekteret og er 
  stadig sosialt forvirret. Men å programmere det er jeg god på.
    </div>
    <br />
    <BlogEntry mdxType="BlogEntry">
      <h3>{`I'm a codeaholic`}</h3>
      <Link to="/blog/codeaholic" mdxType="Link">
  <img src="https://res.cloudinary.com/trailguide-as/image/upload/v1728468082/coding/codeaholics.webp" className="w-48 ml-8 rounded md:float-right" />
      </Link>
      <br />
      <ByLine author="Bjørn Jarle" text=", 9. october 2024" mdxType="ByLine" />
      <br />
      <p>{`My name is Bjørn and I'm a codeaholic. People call me a founder and an entrepreneur.
I am not. I am just a guy who likes to code and make stuff. I am a true codeaholic
who cannot escape the addiction.`}</p>
      <br />
      <p><a parentName="p" {...{
          "href": "./blog/codeaholic"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <h3>{`Would you rather have invested in a gold plated piece of lead, or a lead plated piece of gold?`}</h3>
      <Image src="/coding/me_creating_stuff.jpg" href="blog/gold_or_led" className="mt-8 w-40 md:float-right md:ml-12 shadow-md rounded" mdxType="Image" />
      <br />
      <ByLine author="Bjørn Jarle" text=", 16. june 2023" mdxType="ByLine" />
      <br />
      <p>{`Imagine you are an investor and you discover that the piece of gold you invested in was
just a piece of lead when you started polishing it. Do not get fooled my smooth-talking,
charismatic founders who are better at selling you a great story than actually creating
a great product.`}</p>
      <br />
      <p><a parentName="p" {...{
          "href": "blog/gold_or_led"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/coding/kakediagram.jpeg" href="blog/invester-i-produktet" mdxType="DesktopRightImage" />
      <h3>{`Investere i jævla bra gründere eller jævla bra produkter?`}</h3>
      <br />
      <ByLine author="Bjørn Jarle" text=", 6. februar 2023" mdxType="ByLine" />
      <br />
      <p>{`Investere i jævla dyktige gründere med middels bra produkter, eller et veldig bra
produkt med en middelmådig gründer? Hva er det som `}<strong parentName="p">{`egentlig`}</strong>{` er det beste? Og for hvem?`}</p>
      <br />
      <p><a parentName="p" {...{
          "href": "blog/invester-i-produktet"
        }}>{`Les mer...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/coding/test-suites-passed.jpeg" href="blog/automatisert_testing" mdxType="DesktopRightImage" />
      <h3>{`Automatisert testing`}</h3>
      <br />
      <ByLine author="Bjørn Jarle" text=", 2. februar 2023" mdxType="ByLine" />
      <br />
      <p>{`Hva er det som `}<strong parentName="p">{`egentlig`}</strong>{` er det viktigste med automatisert testing av
programvare? Deployer du på en fredag ettermiddag kanskje?`}</p>
      <br />
      <p><a parentName="p" {...{
          "href": "blog/automatisert_testing"
        }}>{`Les mer...`}</a></p>
    </BlogEntry>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      